import React, { Component } from "react";

import { Route, Switch, Redirect, RouterProps, RouteProps } from "react-router";

import { routes } from '../../routes/routes';

import styles from '../../assets/scss/layouts/PortalLayout/PortalLayout.module.scss';

import bg from "../../assets/images/ramps.jpg";
import logo from "../../assets/images/Logo.svg";
import { Headline1, Caption } from "@material/react-typography";
interface Props extends RouterProps, RouteProps {}

class PortalLayout extends Component<Props> {
  private renderTitle() {
    if (!this.props.location) return;

    for (let i in routes) {
      const route = routes[i];
      if (route.path && route.path.indexOf(this.props.location.pathname) > -1) {
        return route.title.toUpperCase();
      }
    }
    return;
  }

  render() {    
    return (
      <div className={styles.main} style={{ backgroundImage: `url(${bg})` }}>
        <div className={styles.mainContent}>
          <div className={styles.logo}>
            <img src={logo} style={{ height: "150px" }} />
          </div>
          <div className={styles.title}>
            <Headline1>{this.renderTitle()}</Headline1>
          </div>
          <div className={styles.frame}>
            <div className={styles.innerFrame}>
              <Switch>
                {routes.map((r, key) => {
                  return <Route path={r.path} component={r.component} exact={true} key={key} />
                })}
                <Redirect to='/' />
              </Switch>
            </div>
          </div>
          <div className={styles.footer}>
            <div>
              <Caption>
                By logging in your agree to our <a href="/about/Terms%20and%20Conditions">Terms and Conditions</a>
              </Caption>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PortalLayout;
